import React from "react";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";

export const LanguageSwitcher = (): JSX.Element => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLang = i18n.language === "en" ? "th" : "en";
    i18n.changeLanguage(newLang);
  };
  return (
    <button onClick={toggleLanguage} className=" border-none bg-white">
      <div className="flex text-xl">
        <div className={i18n.language === "th" ? "font-bold" : "font-medium"}>
          TH
        </div>
        /
        <div className={i18n.language === "en" ? "font-bold" : "font-medium"}>
          EN
        </div>
      </div>
    </button>
  );
};
