import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const supportedLngs = {
  en: "English",
  th: "ไทย",
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "th",
    debug: false,
    supportedLngs: Object.keys(supportedLngs),
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "./static/locales/{{lng}}/{{ns}}.json",
    },
  });

export default i18n;
